.panel-container-mobile
{
    display: flex;
    flex-flow: column;

    margin-top: 24px;
    margin-bottom: 0px;
    margin-left: -12px;
    margin-right: -12px;

    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 12px;
    padding-right: 12px;

    height: 500px;
    
    background-color: #ffffff;
}

.panel-container-desktop
{
    display: flex;
    flex-flow: column;

    margin-bottom: 24px;
    padding: 24px;

    height: 500px;

    background-color: #ffffff;
}

.panel-header
{
    margin-bottom: 20px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    text-align: center;

    color: #a6a6a6;
}

.panel-image-contain
{
    margin-top: 30px;

    width: 100%;
    height: 100%;
    
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.panel-intro-container-mobile
{
    display: flex;
    flex-flow: column;

    margin-top: 12px;
    margin-bottom: 0px;
    margin-left: -12px;
    margin-right: -12px;

    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 12px;
    padding-right: 12px;
    
    background-color: #ffffff;
}

.panel-intro-container-desktop
{
    display: flex;
    flex-flow: column;

    margin-bottom: 24px;
    padding: 24px;

    background-color: #ffffff;
}

.panel-section
{
    padding-top: 64px;
    
    background-color: #f5f5f5;
}

.panel-section-last
{
    padding-top: 64px;
    padding-bottom: 64px;
    
    background-color: #f5f5f5;
}

.panel-text
{
    padding-top: 8px;
    padding-bottom: 0px;
    margin-bottom: 0px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;

    color: #000000;
}